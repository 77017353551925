import "./App.scss";

import me2022 from "./me2022.png";
import skyscannerLogo from "./logo-skyscanner.svg";
import atlassianLogo from "./logo-atlassian.svg";
import wherebyLogo from "./logo-whereby.svg";

function App() {
  return (
    <div className="App">
      <aside className="banner">
        <strong>👋 Currently exploring new advisory board positions</strong>{" "}
        -{" "}
        <a className="banner-link" href="#contact">
          get in touch
        </a>
      </aside>
      <header className="header max-width">
        <div className="logo-title">
          <div className="logo"></div>
          <h1 className="title">Design leadership, strategy and coaching</h1>
        </div>
        <div className="buttons">
          {/* eslint-disable-next-line jsx-a11y/anchor-has-content */}
          <a
            className="button-link button-link--linkedin"
            href="https://www.linkedin.com/in/hellofergo/"
            target="_blank"
            rel="noreferrer"
          ></a>
          <a className="button-link button-link--contact" href="#contact">
            Contact
          </a>
        </div>
      </header>
      <section className="hero-section max-width">
        <h2 className="hello">Hello, I’m Fergo.</h2>
        <p className="intro">
          I build <span className="highlight">design teams</span>,{" "}
          <span className="highlight">products</span> and{" "}
          <span className="highlight">brands</span>.
        </p>
      </section>
      <section className="value-prop-section max-width">
        <section className="about">
          <h3 className="heading">About me</h3>
          <p className="about-para">
            Based near London, I’m an exec design leader and advisor with over
            20 years experience helping global tech companies build and scale
            design teams through multiple phases of growth. This includes nimble
            series-A start-ups to scale-ups and post-IPO enterprises with
            responsibility for orgs of up to 300 people and design teams of up
            to 70. Held various advisory positions at leading brands including
            Trustpilot and Exclaimer.
          </p>

          <p>
            I'm experienced across a variety of industries in both B2C and B2B
            across Travel, SaaS and Sustainability.
          </p>
        </section>
        <section className="help">
          <h3 className="heading heading-alt">What I can help with</h3>
          <ul className="help-list">
            <li>Product Design strategy and delivery</li>
            <li>UX Research and Content Design</li>
            <li>Brand strategy, creative development & management</li>
            <li>Org design, optimisation and growth</li>
            <li>Career progression frameworks and growth strategies</li>
            <li>Design Systems, platform and product ecosystems</li>
            <li>Change management, conflict and performance management</li>
            <li>Global talent acquisition, development and retention</li>
            <li>Design process, practice and ways of working</li>
            <li>Exec coaching and mentorship</li>
          </ul>
          <a className="button-link button-link--coffee" href="#contact">
            Grab a virtual coffee
          </a>
        </section>
      </section>

      <section className="experience-section">
        <h3 className="experience-title">
          Products and brands I've helped scale include
        </h3>
        <ul className="logo-list">
          <li>
            <img src={skyscannerLogo} alt="Skyscanner" />
          </li>
          <li>
            <img src={atlassianLogo} alt="Atlassian" />
          </li>
          <li>
            <img src={wherebyLogo} alt="Whereby" />
          </li>
        </ul>
      </section>
      <section className="testimonials-section max-width">
        <h2 className="sub-title">What folks say</h2>
        <p className="testimonials-intro">
          People are at the centre of everything I do. Here are a few quotes
          from some of the awesome people I've worked with.
        </p>
        <ul className="testimonials-list">
          <li className="testimonials-list-item">
            <blockquote className="quote">
              Fergo came into our company with a rush of energy and vibrancy,
              bringing along expertise, professionalism, and a lot of empathy.
              He is a kind and considerate manager, a thoughtful leader, and a
              wonderful colleague.
            </blockquote>
            <address rel="author">
              <span className="author">Jessica Zwann</span>
              <span className="company">Chief Operating Officer, Whereby</span>
            </address>
          </li>
          <li className="testimonials-list-item">
            <blockquote className="quote">
              Fergo is a highly empathetic design professional who has a great
              instinct for delivering high-quality customer experiences. Fergo
              is also a great advocate for the role that content design plays in
              creating kick-arse experiences - which makes him the kind of
              leader I like to work with.{" "}
            </blockquote>
            <address rel="author">
              <span className="author">Libby Varcoe</span>
              <span className="company">
                Head of Experience Design, Atlassian
              </span>
            </address>
          </li>
          <li className="testimonials-list-item">
            <blockquote className="quote">
              I worked directly with Fergo for 3 years. We had a solid
              relationship based on directness and honesty, learning a lot from
              each other. During this time I saw Fergo grow into a great design
              leader who was well respected and liked, being incredibly
              reliable, communicative and supportive. Every leader would benefit
              from a Fergo on their team.
            </blockquote>
            <address rel="author">
              <span className="author">Steve 'Buzz' Pearce</span>
              <span className="company">SVP Design, Skyscanner</span>
            </address>
          </li>
        </ul>
      </section>
      <section id="contact" className="contact-section">
        <div className="contact-content">
          <div className="contact-row">
            <h2 className="sub-title">Want to chat?</h2>
            <p>Drop me a message below to set up a quick chat.</p>
            <form
              className="contact-form"
              name="contact"
              method="POST"
              data-netlify="true"
            >
              <input type="hidden" name="form-name" value="contact" />
              <div className="name-group">
                <div className="firstname-group">
                  <label className="field-label" htmlFor="firstname">
                    First name
                  </label>
                  <input
                    className="field firstname"
                    type="text"
                    id="firstname"
                    name="firstname"
                    required
                  />
                </div>
                <div className="surname-group">
                  <label className="field-label" htmlFor="surname">
                    Surname
                  </label>
                  <input
                    className="field surname"
                    type="text"
                    id="surname"
                    name="surname"
                    required
                  />
                </div>
              </div>
              <label className="field-label" htmlFor="email">
                Email
              </label>
              <input
                className="field email"
                type="email"
                id="email"
                name="email"
                required
              />
              <label className="field-label" htmlFor="message">
                Message
              </label>
              <textarea
                id="message"
                className="field message"
                name="message"
                required
              />
              <button className="submit-button" id="send" type="submit">
                Send
              </button>
            </form>
          </div>
          <img
            className="photo"
            src={me2022}
            alt="headshot of Fergo"
            width="300"
            height="300"
          />
        </div>
      </section>
    </div>
  );
}

export default App;
